import React from 'react'
import { graphql } from 'gatsby'
import Responsive from 'react-responsive'

import Layout from '../components/layout'
import Box from '../components/box'
import Name from '../components/name'
import Item from '../components/item'
import Header from '../components/header'
import LinkPage from '../components/linkpage'

const pagetitle = 'serveur'

const ServeurPage = ({
  data: {
    allServeurJson: { edges },
  },
}) => {
  const StorageItems = edges
    .filter(edge => edge.node.item.category === 'storage') // You can filter your posts based on some criteria
    .map(edge => <Item key={edge.node.id} items={edge.node.item} />)

  const DownloadItems = edges
    .filter(edge => edge.node.item.category === 'download') // You can filter your posts based on some criteria
    .map(edge => <Item key={edge.node.id} items={edge.node.item} />)

  const NetworkItems = edges
    .filter(edge => edge.node.item.category === 'network') // You can filter your posts based on some criteria
    .map(edge => <Item key={edge.node.id} items={edge.node.item} />)

  const Desktop = props => <Responsive {...props} minWidth={992} />
  const Mobile = props => <Responsive {...props} maxWidth={991} />

  return (
    <Layout>
      <Desktop>
        <Header title={pagetitle} size="destkop" />
        <Box>
          <Name cat="storage" />
          {StorageItems}
        </Box>
        <Box>
          <Name cat="network" />
          {NetworkItems}
        </Box>
        <Box>
          <Name cat="download" />
          {DownloadItems}
        </Box>
        <LinkPage linktxt="hello" linkto="/" />
      </Desktop>
      <Mobile>
        <Header size="mobile" />
        <Box>
          <Name cat="storage" />
          {StorageItems}
        </Box>
        <Box>
          <Name cat="network" />
          {NetworkItems}
        </Box>
        <Box>
          <Name cat="download" />
          {DownloadItems}
        </Box>
      </Mobile>
    </Layout>
  )
}

export const pageQuery = graphql`
  query itemsserveur {
    allServeurJson {
      edges {
        node {
          id
          item {
            title
            link
            category
            logo {
              childImageSharp {
                fixed(width: 16, quality: 85) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ServeurPage
